<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="pv-30 flex flex-ac flex-w">
        <template v-if="cardTitle == '用户卡片'">
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">用户名：</span>
            <el-input class="w-250" v-model="retrieveForm.userName" clearable></el-input>
          </div>
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">手机号：</span>
            <el-input class="w-250" v-model="retrieveForm.phone" clearable></el-input>
          </div>
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">人员类型：</span>
            <el-select class="w-250" v-model="retrieveForm.isTest" clearable placeholder="请选择">
              <el-option label="正式" :value="0">
              </el-option>
              <el-option label="测试" :value="1">
              </el-option>
            </el-select>
          </div>
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">是否使用：</span>
            <el-select class="w-250" v-model="retrieveForm.isUse" clearable placeholder="请选择">
              <el-option label="未使用" :value="0">
              </el-option>
              <el-option label="已使用" :value="1">
              </el-option>
            </el-select>
          </div>
          <div class="mr-30 mt-20 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">创建时间：</span>
            <el-date-picker class="w-250" v-model="startEndDate" type="daterange" valueFormat="yyyy-MM-dd"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handPickerChange">
            </el-date-picker>
          </div>
          <div class="mr-30 mt-20 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">卡片名称：</span>
            <el-input class="w-250" v-model="retrieveForm.cardName" clearable></el-input>
          </div>
          <div class="mr-30 mt-20 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">卡片来源：</span>
            <el-select class="w-250" v-model="retrieveForm.cardType" placeholder="请选择">
              <el-option v-for="item in cardTypes" :key="item.value" :label="item.key" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </template>
        <template v-if="cardTitle == '卡片记录'">
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">用户名：</span>
            <el-input class="w-250" v-model="retrieveForm.recUserName" clearable></el-input>
          </div>
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">手机号：</span>
            <el-input class="w-250" v-model="retrieveForm.recUserPhone" clearable></el-input>
          </div>
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">邀/赠人：</span>
            <el-input class="w-250" v-model="retrieveForm.sendUserName" clearable></el-input>
          </div>
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20">邀/赠手机号：</span>
            <el-input class="w-250" v-model="retrieveForm.sendUserPhone" clearable></el-input>
          </div>
          <div class="mr-30 mt-20 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">卡片名称：</span>
            <el-input class="w-250" v-model="retrieveForm.cardName" clearable></el-input>
          </div>
          <div class="mr-30 mt-20 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-80">卡片来源：</span>
            <el-select class="w-250" v-model="retrieveForm.cardRecordType" clearable placeholder="请选择">
              <el-option v-for="item in cardRecordTypes" :key="item.value" :label="item.key" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </template>
        <div class="mt-20">
          <el-button type="primary" @click="submitSearch">查询</el-button>
        </div>
        <div class="mt-20 flex-1 ta-r">
          <span class="ml-40 fc-666 fs-16 pointer" @click="getRecord('卡片记录')" v-if="cardTitle == '用户卡片'">卡片记录</span>
          <span class="ml-40 fc-666 fs-16 pointer" @click="getRecord('用户卡片')" v-if="cardTitle == '卡片记录'">返回</span>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="cardTitle == '用户卡片'?columns:recordColumns"
        height="100%" :border="false" @row-click="headerRowClick" v-loading="loading" :row-style="{ cursor: 'pointer' }"
        v-if="tableData.length > 0">
        <template v-slot:userImg="scope">
          <img :src="scope.row.userImg" alt="" class="smallImg" v-if="scope.row.userImg">
        </template>
        <template v-slot:recUserImg="scope">
          <img :src="scope.row.recUserImg" alt="" class="smallImg" v-if="scope.row.recUserImg">
        </template>
        <template v-slot:cardImg="scope">
          <img :src="scope.row.cardImg" alt="" class="smallImg" v-if="scope.row.cardImg">
        </template>
        <template v-slot:sendUserImg="scope">
          <img :src="scope.row.sendUserImg" alt="" class="smallImg" v-if="scope.row.sendUserImg">
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
    </TableHeightFull>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'drawsGood',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'cardTypes': state => state.dictList ? state.dictList.card_type : [],  //卡片类型
      'cardRecordTypes': state => state.dictList ? state.dictList.card_record_type : [],  //卡片来源类型
    })
  },
  data () {
    return {
      cardTitle: '用户卡片',
      retrieveForm: {
        userName: '',
        phone: '',
        isTest: 0,//默认不过期
        isUse: '', //默认未使用
        startDate: '',
        endDate: '',
        cardName: '',
        cardType: '',
        //卡片记录
        recUserName: '',//用户名称
        recUserPhone: '',//用户手机号
        sendUserName: '',//赠送人名称
        sendUserPhone: '',//赠送人手机号
        cardName: '',
        cardRecordType: '',
      },
      startEndDate: [], //时间区间
      loading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { columnType: 'custom', label: '头像', prop: 'userImg', align: 'left', width: '80' },
        { label: '用户名', prop: 'userName', align: 'left', 'show-overflow-tooltip': true },
        { label: '手机号', prop: 'phone', align: 'left', width: '120', 'show-overflow-tooltip': true },
        { label: '卡片名称', prop: 'cardName', align: 'left', 'show-overflow-tooltip': true },
        { columnType: 'custom', label: '卡片图片', prop: 'cardImg', align: 'left', width: '80' },
        {
          label: '卡片来源', prop: 'cardType', align: 'left', width: '150', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.cardTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        {
          label: '是否使用', prop: 'isUse', align: 'left', width: '100', 'show-overflow-tooltip': true, formatData: (e) => {
            return e == 0 ? '未使用' : '已使用'
          }
        },
        { label: '创建时间', prop: 'createDate', align: 'left', width: '170', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '80',
          buttons: [
            { type: 'text', text: '删除', fn: this.deleteList, },
          ],
        },
      ],
      recordColumns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { columnType: 'custom', label: '头像', prop: 'recUserImg', align: 'left', width: '80' },
        { label: '用户名', prop: 'recUserName', align: 'left', 'show-overflow-tooltip': true },
        { label: '手机号', prop: 'recUserPhone', align: 'left', width: '120', 'show-overflow-tooltip': true },
        { label: '卡片名称', prop: 'cardName', align: 'left', 'show-overflow-tooltip': true },
        { columnType: 'custom', label: '卡片图片', prop: 'cardImg', align: 'left', width: '80' },
        {
          label: '卡片来源', prop: 'cardRecordType', align: 'left', width: '150', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.cardRecordTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        { columnType: 'custom', label: '头像', prop: 'sendUserImg', align: 'left', width: '80' },
        { label: '邀请/赠送', prop: 'sendUserName', align: 'left', 'show-overflow-tooltip': true },
        { label: '邀/赠手机号', prop: 'sendUserPhone', align: 'left', width: '120', 'show-overflow-tooltip': true },
        { label: '创建时间', prop: 'createDate', align: 'left', width: '170', 'show-overflow-tooltip': true },
      ],
      tableData: [],
    }
  },
  mounted () {
    this.getTableList()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableList()
    },
    //时间变化
    handPickerChange (value) {
      if (value && value.length != 0) {
        this.retrieveForm.startDate = value[0]
        this.retrieveForm.endDate = value[1]
      } else {
        this.retrieveForm.startDate = ""
        this.retrieveForm.endDate = ""
      }
    },
    //导出
    exportExcel () { },
    //切换 卡片 记录
    getRecord (title) {
      this.cardTitle = title
      if (title == '用户卡片') {
        this.retrieveForm = {
          userName: '',
          phone: '',
          isTest: 0,//默认不过期
          isUse: '', //默认未使用
          startDate: '',
          endDate: '',
          cardName: '',
          cardType: '',
        }
      } else if (title == '卡片记录') {
        this.retrieveForm = {
          recUserName: '',//用户名称
          recUserPhone: '',//用户手机号
          sendUserName: '',//赠送人名称
          sendUserPhone: '',//赠送人手机号
          cardName: '',
          cardRecordType: '',
        }
      }
      this.$nextTick(() => {
        this.getTableList()
      })
    },
    //获取表格数据
    getTableList () {
      this.loading = true
      let apiUrl = ''
      if (this.cardTitle == '用户卡片') {
        apiUrl = '/userCard/findAppletUserCardList'
      } else if (this.cardTitle == '卡片记录') {
        apiUrl = '/userCard/findCardIncome'
      }
      this.tableData = []
      this.$http.get(apiUrl, {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          ...this.retrieveForm
        }
      }).then(({ data: result }) => {
        this.loading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      })
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/userCard/removeById', {
          id: row.id,
        }).then(({ data: result }) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getTableList()
        })
      }).catch(_ => { })
    },
    //跳转详情
    headerRowClick (row) { },
  },
}
</script>

<style lang="less" scoped>
</style>